aw-wizard-navigation-bar {
  margin-bottom: 5px !important;
}

aw-wizard-navigation-bar ul.steps-indicator li a .label {
  text-transform: none !important;
  color: #595859;
  font-size: 14px;
  font-weight: 400 !important;
}

.step-indicator span {
  color: #595859;
}

li.done.navigable .step-indicator span {
  display: none !important;
}

li.done.navigable .step-indicator:after {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  font-weight: 900;
}

li.done.navigable .step-indicator {
  background-color: var(--bs-primary) !important;
  border: 2px solid var(--bs-primary) !important;
  color: #fff !important;
}

li.done.navigable .label {
  color: var(--bs-primary) !important;
}

ul.steps-indicator {
  padding: 40px 0 10px 0 !important;
}

ul.steps-indicator li .step-indicator {
  top: -32px !important;
  left: calc(50% - 20px) !important;
  width: 32px !important;
  height: 32px !important;
  line-height: 30px !important;
}
ul.steps-indicator li:not(:last-child):after {
  width: calc(100% - 110px) !important;
  left: calc(50% + 45px) !important;
  top: -16px !important;
}

ul.steps-indicator li.done.navigable:not(:last-child):after {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
}

li.current .step-indicator {
  background-color: var(--bs-primary) !important;
  border: 2px solid var(--bs-primary) !important;
  color: #fff !important;
}

li.current .step-indicator span {
  color: #fff !important;
}

li.done .step-indicator {
  border: 2px solid #e6e6e6 !important;
  color: #e6e6e6 !important;
}

.current .label {
  color: var(--bs-primary) !important;
}
