.tooltip-info {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-info .tooltiptext {
  visibility: hidden;
  width: 400px;
  background: #ffffff !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  color: #555;
  text-align: left;
  border-radius: 2px;
  padding: 20px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: 10px;
  margin-top: -90px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
  font-size: 13px;
}

.tooltip-info li {
  padding-top: 10px;
}

.tooltip-info:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.ngb-tooltip-wide-m {
  .tooltip-inner {
    max-width: 400px;
    min-width: 400px;
  }
}
