// Add windowClass custom to modal:

.modal.custom {
  box-sizing: border-box;

  .modal-dialog {
    &.modal-l {
      max-width: 560px;
    }

    &.modal-lg {
      max-width: 644px;
      min-width: 560px;
      width: fit-content;
    }

    &.modal-xl {
      width: 644px;
      min-width: 644px;
    }

    &.modal-xxl {
      width: 800px;
      min-width: 800px;
    }
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 0 8px 48px rgba(24, 23, 24, 0.08);
    border-radius: 4px;
  }

  .btn-close {
    width: 18px;
    height: 18px;
    font-size: 18px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 26px;
    top: 26px;
    color: #595859;
  }

  .modal-header {
    position: relative;
    width: 100%;
    height: 64px;
    background: #f6f6fc;
    border-radius: 4px 4px 0 0;
    padding: 16px 80px 16px 40px;
    border: none;

    .modal-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #181718;
    }

    .btn-close {
      right: 26px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.content-space-between {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .modal-body {
    padding: 32px 40px 40px 40px;

    .modal-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #181718;

      margin-bottom: 24px;
    }

    .col-form-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #181718;

      padding: 0 0 8px 0;
    }

    .input-group {
      position: relative;
      padding: 0;
    }

    .form-control {
      height: 40px;
      border: 1px solid #d7d7d9;
      border-radius: 4px;

      &.is-invalid {
        background: none;
      }

      &.with-action {
        position: relative;
        padding-right: 46px;

        &:focus + .form-control-action {
          z-index: 3;
        }
      }

      &-action {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &.disabled {
          pointer-events: none;
        }

        &-btn {
          display: flex;
        }
      }
    }

    .btn.btn-control-action {
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      box-shadow: none;
      &:disabled {
        color: grey;
      }
    }

    .content-text,
    .content-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #181718;
    }

    .content-title {
      font-size: 15px;
    }
  }

  .modal-footer {
    padding: 0 40px 40px 40px;
    border: none;

    &.inner {
      padding: 0 !important;
    }

    .btn {
      height: 40px;

      &.btn-main {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        background: var(--bs-primary);
      }

      &.btn-outline {
        background: transparent;
        border-color: var(--bs-primary);
        color: var(--bs-primary);

        &:hover {
          background: var(--bs-primary);

          color: #ffffff;
        }
      }
    }

    &.one-action {
      .btn {
        width: 100%;
        margin: 0;
      }
    }

    &.two-actions {
      .btn {
        width: calc(50% - 8px);
        margin: 0;

        & + .btn {
          margin-left: 16px;
        }
      }
    }

    &.mobile-column {
      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;

        .btn {
          width: 100%;
          &:first-child {
            margin: 0!important;
          }
          & + .btn {
            margin: 16px 0 0!important;
          }
        }
      }
    }
  }

  &.new {
    .modal-header {
      padding: 16px 25px;
      .modal-title {
        max-width: 100%;
        padding-right: 35px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .modal-body {
      padding-top: 24px;
      padding-bottom: 28px;
    }
    .modal-footer {
      padding-bottom: 48px;
    }
    .form-group {
      margin-bottom: 28px;
    }
  }

  &.confirm {
    .btn-close {
      top: 22px;
      right: 22px;
      transform: none;
      width: 15px;
      height: 15px;
      font-size: 14px;
    }
    .modal-body {
      min-height: 96px;
      padding: 24px;
    }
    .modal-footer {
      padding: 0 24px 32px 24px;
    }
  }
}

@media (max-width: 800px) {
  .modal.custom .modal-dialog.modal-xl {
    width: 100%;
    min-width: 100%;
    margin: 0;
  }
}

@media (max-width: 575.98px) {
  .modal.custom {
    .modal-dialog {
      &.modal-default,
      &.modal-large,
      &.modal-lg {
        max-width: 100% !important;
        margin: 0;
      }

      &.modal-lg {
        min-width: 100%;
        width: 100%;
      }

      .modal-content {
        height: 100%;
        min-height: 100vh;
        max-height: 2000px;
      }

      .modal-header {
        height: 60px;
        padding: 16px 56px 16px 16px;

        .modal-title {
          font-size: 20px;
          line-height: 28px;
        }

        .btn-close {
          right: 22px;
        }
      }

      .modal-body {
        padding: 24px 16px 34px 16px;
      }

      .modal-footer {
        padding: 0 16px 34px 16px;
      }
    }
  }
}
