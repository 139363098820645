.vimeo-logo-blue {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKYXJpYS1sYWJlbD0iVmltZW8iIHJvbGU9ImltZyIKdmlld0JveD0iMCAwIDUxMiA1MTIiPjxyZWN0CndpZHRoPSI1MTIiIGhlaWdodD0iNTEyIgpyeD0iMTUlIgpmaWxsPSIjMWViOGViIi8+PHBhdGggZD0ibTQxOCAxODVjLTE5IDEwOS0xMjggMjAyLTE2MSAyMjMtMzIgMjEtNjItOS03My0zMC0xMi0yNi00OS0xNjQtNTktMTc2LTktMTItMzkgMTItMzkgMTJsLTEzLTE5czU5LTcxIDEwNC03OWM0Ny0xMCA0NyA3MyA1OSAxMTggMTEgNDUgMTggNzAgMjcgNzAgMTAgMCAyOS0yNCA0OS02MyAyMS0zNy0xLTcxLTQxLTQ3IDE3LTk1IDE2Ni0xMTggMTQ3LTl6IiBmaWxsPSIjZmZmZmZmIi8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.youtube-logo-red {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKYXJpYS1sYWJlbD0iWW91VHViZSIgcm9sZT0iaW1nIgp2aWV3Qm94PSIwIDAgNTEyIDUxMiIKZmlsbD0iI2VkMWQyNCI+PHJlY3QKd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiCnJ4PSIxNSUiLz48cGF0aCBkPSJtNDI3IDE2OWMtNC0xNS0xNy0yNy0zMi0zMS0zNC05LTIzOS0xMC0yNzggMC0xNSA0LTI4IDE2LTMyIDMxLTkgMzgtMTAgMTM1IDAgMTc0IDQgMTUgMTcgMjcgMzIgMzEgMzYgMTAgMjQxIDEwIDI3OCAwIDE1LTQgMjgtMTYgMzItMzEgOS0zNiA5LTEzNyAwLTE3NCIgZmlsbD0iI2ZmZmZmZiIvPjxwYXRoIGQ9Im0yMjAgMjAzdjEwNmw5My01MyIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
