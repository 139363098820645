@use "node_modules/@angular/cdk" as cdk;

@include cdk.a11y-visually-hidden();
@include cdk.overlay();
@include cdk.text-field();

.cdk-overlay-dark-backdrop {
  background: rgba(24, 23, 24, 0.9);
}

.cdk-overlay-pane {
  width: 100%;
  background: white;
  border-radius: 2px;
  max-height: 100vh;

  .mat-dialog-container {
    background: #ffffff;
    box-shadow: 0 8px 48px rgba(24, 23, 24, 0.08);
    border-radius: 4px;

    max-height: 100%;
    padding: 0;
  }

  &.modal-standard {
    max-width: 560px !important;
    min-height: 250px;

    &-m {
      max-width: 640px !important;
      .modal-header {
        padding: 16px 64px 16px 24px;
        .btn-close {
          top: 22px;
        }
      }
      .modal-body {
        padding: 24px 40px 48px 40px;
      }
    }

    &-s {
      max-width: 540px !important;
    }
  }

  &.modal-full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    min-height: 100vh !important;
    min-width: 100vw !important;
    overflow: hidden;
    margin: 0;
    background-color: #fff;
  }

  &.modal-large {
    max-width: 650px !important;
  }

  .modal-header {
    width: 100%;
    background: #f6f6fc;
    border-radius: 4px 4px 0 0;
    padding: 16px 50px 16px 40px;
    border: none;

    &.with-subtitle {
      flex-direction: column;
      align-items: flex-start;
      max-height: 100%;
    }

    .modal-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #181718;
    }

    .modal-subtitle {
      color: #595859;
      font-size: 14px;
      line-height: 20px;
    }

    .btn-close {
      width: 18px;
      height: 18px;
      font-size: 18px;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 26px;
      top: 26px;
      color: #595859;
    }

    &.content-space-between {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .modal-body {
    padding: 32px 40px 40px 40px;

    .modal-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #181718;
    }

    .col-form-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #181718;

      padding: 0 0 8px 0;
    }

    .form-control {
      height: 40px;
      border: 1px solid #d7d7d9;
      border-radius: 4px;
    }

    &.with-footer {
      padding-bottom: 44px;

      .modal-text {
        margin-bottom: 24px;
      }
    }
  }

  .modal-footer {
    padding: 0 40px 40px 40px;
    border: none;

    &.inner {
      padding: 0 !important;
    }

    .btn.btn-outline {
      background: transparent;
      border-color: #2520b6;
      color: #2520b6;

      &:hover {
        background: #2520b6;

        color: #ffffff;
      }
    }

    &.one-action {
      .btn {
        width: 100%;
        margin: 0;
      }
    }

    &.two-actions {
      .btn {
        width: calc(50% - 18px);
        margin: 0;

        & + .btn {
          margin-left: 36px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .cdk-overlay-pane {
    .mat-dialog-container {
      height: 100%;
      min-height: 100vh;
      max-height: 2000px;
    }

    &.modal-large,
    &.modal-standard {
      max-width: 100% !important;
      margin: 0;
    }

    .modal-header {
      max-height: 60px;
      padding: 16px 56px 16px 16px;

      .modal-title {
        font-size: 20px;
        line-height: 28px;
      }

      .btn-close {
        right: 22px;
      }
    }

    .modal-body {
      padding: 24px 16px 34px 16px;
    }

    .modal-footer {
      padding: 0 16px 34px 16px;
    }
  }
}
