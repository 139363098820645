.datatables th {
  white-space: nowrap;
}

.page-link {
  padding: 0.4rem 0.8rem !important;
  border: 1px solid #d7d7d9;
  border-radius: 4px !important;
  margin-right: 7px;
}

.page-item.active .page-link {
  background-color: #fff !important;
  color: var(--bs-primary) !important;
  border: 2px solid var(--bs-primary) !important;
}

.page-item.disabled .page-link {
  background-color: #f6f6fc !important;
}

a[aria-label="Previous"] {
  span {
    display: none !important;
  }

  &::before {
    font-family: "Font Awesome\ 5 Free";
    font-weight: 600;
    content: "\f104" !important;
  }
}

a[aria-label="Next"] {
  span {
    display: none !important;
  }

  &::before {
    font-family: "Font Awesome\ 5 Free";
    font-weight: 600;
    content: "\f105" !important;
  }
}

.column-action .btn {
  padding: 0 5px !important;
}

.column-nowrap {
  white-space: nowrap;
}

.column-wrap {
  overflow-wrap: anywhere;
  &.main-column {
    max-width: 200px;
  }
  .table-icon-wrapper {
    padding: 0.47rem 0.75rem;
  }
}

.td-breakable-text {
  white-space: break-spaces;
}

.select-page-size {
  font-size: 13px !important;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"/></svg>')
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.select-page-size option:focus,
.select-page-size option:hover {
  outline: none;
  background: var(--bs-primary);
}

.datatables th {
  padding: 0.85rem 0.85rem !important;
}

.datatables td {
  min-width: 130px !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.long-column {
  min-width: 200px !important;
}

.min-column {
  min-width: 30px !important;
}

.datatables .column-action-3 {
  width: 130px !important;
  min-width: 130px !important;
  vertical-align: top !important;
}

.datatables .column-action-2 {
  width: 90px !important;
  min-width: 90px !important;
  vertical-align: top !important;
}

.btn-action-icon:hover {
  color: var(--bs-primary) !important;
  box-shadow: inset 100px 0 0 0 #f8f9fa;
}

.btn-action-icon-delete:hover {
  color: #ff0000 !important;
  box-shadow: inset 100px 0 0 0 #f8f9fa;
}

.table-light th {
  color: #595859 !important;
  border-color: #f6f6fc !important;
  background-color: #f6f6fc !important;
}

table {
  color: #181718 !important;
  font-size: 14px !important;
}

@media only screen and (max-width: 500px) {
  .pagination-content {
    position: relative !important;
    display: block !important;
  }
}

.column-position {
  min-width: 20px !important;
  width: 20px !important;
}

.datatables .form-check-input {
  height: 18px !important;
  width: 18px !important;
}

.datatables thead tr th:before {
  content: "";
}

.datatables thead tr th:after {
  content: "";
}

.sort-icon {
  font-weight: 900;
  font-size: 1rem;
  font-family: "Font Awesome\ 5 Free" !important;
}

.datatables thead tr .asc .sort-icon:after {
  opacity: 1;
}

.datatables thead tr .desc .sort-icon:before {
  opacity: 1;
}

.datatables thead tr th .sort-icon:before {
  content: "\f0de";
  position: relative !important;
  left: 15px;
  opacity: 0.3;
}

.datatables thead tr th .sort-icon:after {
  content: "\f0dd";
  opacity: 0.3;
  position: relative !important;
  left: 5px;
}

.datatables .td-fixed-right {
  z-index: 5;
  width: 50px !important;
  min-width: 50px !important;
  right: 0;
  position: sticky;
  position: -webkit-sticky;
  vertical-align: top !important;
  background-color: #fff;
}
