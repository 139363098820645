.spinner-border-wrapper {
  position: relative;
  pointer-events: all;

  &.loading {
    pointer-events: none;
  }

  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
