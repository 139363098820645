@media only screen and (min-width: 576px) {
  default-widget-modal {
    max-width: 560px !important;
  }

  .modal-xxl .default-widget-modal {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 576px) {
  .modal-xxl {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .modal-xxl {
    max-width: 65% !important;
  }
}

@media only screen and (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 560px !important;
  }

  .modal-xxl {
    max-width: 65% !important;
    min-width: 65% !important;
  }

  .modal-xxl .btn-modal-footer {
    width: auto !important;
  }
}

.default-widget-modal {
  .modal-dialog {
    &.modal-lg {
      max-width: 560px !important;
    }
  }

  .widget-icon-tooltip {
    top: 0 !important;
  }

  dropzone > .dropzone.dz-wrapper .dz-message .dz-text {
    font-size: 20px;
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 0 8px 48px rgba(24, 23, 24, 0.08);
    border-radius: 4px;
  }

  .modal-header {
    display: block;
    width: 100%;
    min-height: 64px;
    background: #f6f6fc;
    border-radius: 4px 4px 0 0;
    padding: 16px 40px;
    border: none;

    .modal-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #181718;
    }

    .btn-close {
      width: 12px;
      height: 12px;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 26px;
      right: 26px;
    }
  }

  .modal-body {
    padding: 32px 40px 0px 40px;

    .modal-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #181718;

      margin-bottom: 24px;
    }

    .col-form-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #181718;
      padding: 0 0 8px 0;
    }

    .form-control {
      height: 40px;
      border: 1px solid #d7d7d9;
      border-radius: 4px;
    }
  }

  .modal-footer {
    padding: 0 20px 20px 20px;
    border: none;

    &.inner {
      padding: 0 !important;
    }

    .btn {
      background: var(--bs-primary);
      min-width: 100px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;

      &.btn-outline {
        background: transparent;
        border-color: var(--bs-primary);
        color: var(--bs-primary);

        &:hover {
          background: var(--bs-primary);

          color: #ffffff;
        }
      }
    }

    &.one-action {
      .btn {
        width: 100%;
        margin: 0;
      }
    }

    &.two-actions {
      .btn {
        width: calc(50% - 8px);
        margin: 0;

        & + .btn {
          margin-left: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .modal-body {
      padding: 20px 24px 0px 24px;
    }
    .modal-footer {
      padding: 10px;
    }
  }

  &.preview-widget-modal {
    .modal-body {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 450px) {
  .default-widget-modal {
    min-height: calc(100vh - 24px);
  }
}

.border-radius-left-none .form-control {
  border-radius: 0px 4px 4px 0px !important;
}
