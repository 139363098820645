@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: url("SF-Pro-Display-Regular.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("SF-Pro-Display-Medium.otf");
}
@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 600;
  src: url("SF-Pro-Display-Semibold.otf");
}
@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 700;
  src: url("SF-Pro-Display-Bold.otf");
}
