//
// _footer.scss
//

.footer {
  display: flex;
  align-items: center;
  bottom: 0;
  padding: 5px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: 250px;
  height: $footer-height;
  background-color: $footer-bg;

  @media (max-width: 991.98px) {
    left: 0;
  }

  &.view-type-simple {
    background-color: $footer-bg-simple;
    color: $footer-color-simple;
    font-size: 14px;
    text-align: center;
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;

    @media (max-width: 991.98px) {
      left: 0;
    }
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}
