@import "@fontsource/inter";
@import "assets/scss/custom/components/modal";
@import "assets/scss/custom/components/datatables";
@import "assets/scss/custom/components/cardcustom";
@import "assets/scss/custom/components/steps";
@import "assets/scss/custom/components/ngxdatatables";
@import "assets/scss/custom/components/sidebar";
@import "assets/scss/custom/components/tooltip";
@import "assets/scss/modal";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body[data-sidebar="dark"].vertical-collpsed {
  min-height: 100% !important;
}

.tooltip-inner {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #181718 !important;
  z-index: 1020;
  background: #ffffff !important;
  box-shadow: 0px 4px 16px rgba(24, 23, 24, 0.08) !important;
  border-radius: 2px;
  opacity: 1;
  filter: alpha(opacity=100);
  padding: 12px;
}

.tooltip.show {
  opacity: 1 !important;
}

body {
  font-family: "Inter" !important;
  font-weight: 400;
  color: #181718 !important;

  @media not print {
    background: #f6f6fc !important;
  }

  @media print {
    background: white !important;
  }
}

body.modal-open {
  overflow: hidden;
}

.font-bold {
  font-weight: 600 !important;
  color: #181718 !important;
}

.text-nowrap {
  white-space: nowrap !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #181718 !important;
}

.modal-body {
  white-space: pre-line;
}

.v-align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
}

app-horizontal .image-border {
  height: 300px !important;
}

.icon-social .form-control {
  border-radius: 0 !important;
  border-left: 0;
  height: 40px;
}

.br-none {
  border-radius: 0 !important;
}

.row-eq-height {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.border-bottom {
  border-bottom: 1px solid #d7d7d9;
}

.border-bottom-black {
  border-bottom: 2px solid #000000;
}

h5 {
  font-weight: 600 !important;
}

.disabled-block input,
.disabled-block .btn,
.disabled-block .ng-select,
.disabled-block .ng-input,
.disabled-block .form-check {
  opacity: 0.65 !important;
  pointer-events: none !important;
}

.disabled-block .cancel {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.disabled .ng-select,
.disabled .ng-input {
  opacity: 1 !important;
  pointer-events: none !important;
}

.form-check-input.disabled,
.form-check-input[disabled] {
  &,
  & ~ .form-check-label {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }
}

.bg-light {
  background: #f6f6fc !important;
}

.hide-opacity {
  opacity: 0;
  pointer-events: none;
}

// for print:
@media print {
  * {
    margin: 0;
    padding: 0;
  }

  @page {
    margin: 0 !important;
    size: A4 landscape !important;
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid;

    thead,
    tfoot {
      display: none !important;
    }
  }

  html,
  body {
    height: 100%;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

:root .ng-select .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: var(--bs-primary) !important;
}

:root .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-primary) !important;
}

:root .btn-outline-primary:not(:hover) {
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

:root .btn-outline-primary.active {
  background-color: var(--bs-primary) !important;
  color: #ffffff !important;
}

.text-primary {
  color: var(--bs-primary) !important;
}

.btn-link {
  color: var(--bs-primary) !important;
}

.h-40 {
  height: 40px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #cfd4d9;
}

.bg-white {
  background-color: #ffffff !important;
}

.strong-normal strong {
  font-weight: normal !important;
}

/* Importing Bootstrap SCSS file. */
@import "../../../node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.dashboard image {
  width: 20px !important;
  height: 20px !important;
}

.dashboard text {
  fill: #495057 !important;
}

.dropdown-toggle::after {
  display: none;
}

.chart-dom-menu {
  background: #fff;
  position: absolute;
  top: 24px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 3px;
  right: 10px;
  min-width: 110px;

  display: none;
}

.dom-dashboard-menu-open {
  display: block;
  pointer-events: all !important;
  z-index: 11; // below `dashboard-page-filter`
}

.chart-dom-item {
  padding: 6px 7px;
  font-size: 12px;
  pointer-events: all !important;
  cursor: pointer;
}

.chart-dom-item:hover {
  background: #eee !important;
  pointer-events: all !important;
  cursor: pointer;
}

.bold-span {
  span {
    font-weight: 600;
  }
}

.item-title {
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
}

.details-template-preview .item-title {
  display: none !important;
}
.color-content {
  min-width: 500px !important;
}
.color-select .ng-value {
  width: 96% !important;
  min-width: 500px !important;
}

@media only screen and (max-width: 520px) {
  .color-content {
    min-width: 100% !important;
  }
  .color-select .ng-value {
    min-width: 100% !important;
  }
}

app-horizontal .navbar-brand-box {
  background: #fff !important;
}

@media (min-width: 1200px) {
  app-horizontal .container-fluid {
    max-width: 100% !important;
  }

  .tooltip-menu-horizontal {
    display: none !important;
  }
}

.min-width-100 {
  min-width: 100% !important;
}

:root .text-primary .mdi,
.btn-link.text-primary {
  color: var(--bs-primary) !important;
}

:root .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .mdi {
  color: var(--bs-primary);
}

:root .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .mdi,
.ng-value-label .mdi {
  color: var(--bs-primary) !important;
}

:root .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-marked .mdi {
  color: #fff !important;
}

.form-checkbox {
  height: 18px !important;
  width: 18px !important;
}

.font-16 {
  font-size: 16px !important;
  line-height: 24px !important;
}
