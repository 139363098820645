.vertical-menu {
  background: #181718 !important;
  margin: 0;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  margin: 0 !important;
  line-height: 1.5;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  background: #181718 !important;
  color: #ffffff;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li {
  border: none !important;
  background: #181718 !important;
  padding: 0 !important;
}

.vertical-collpsed #sidebar-menu ul li ul.sub-menu li {
  padding: 0 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  background: #181718 !important;
}

.navbar-brand-box {
  background: #181718 !important;
}

#side-menu {
  padding: 0px 16px !important;
}

#sidebar-menu {
  padding: 0 !important;
}

#sidebar-menu .br-top-1 {
  border-top: 1px solid #3d3c3d;
}

#sidebar-menu ul li:last-child {
  border: none;
}

#sidebar-menu .metismenu li {
  padding: 8px 0px;
  border-bottom: 1px solid #3d3c3d;
}

#sidebar-menu ul li ul.sub-menu li {
  border: none !important;
  padding: 0px 12px;
  vertical-align: middle !important;
}

#sidebar-menu ul li ul.sub-menu li i {
  font-size: 16px !important;
}

#sidebar-menu li a,
#sidebar-menu .bottom-nav-item a {
  display: block;
  text-decoration: none;
  color: #bebebe !important;
  white-space: nowrap;
  padding: 8px !important;
  font-size: 14px !important;
}

#sidebar-menu li a.custom-hover-link.custom-hover,
#sidebar-menu li a:not(.custom-hover-link):hover,
#sidebar-menu li a.active {
  text-decoration: none;
  color: #fff !important;
  background: #2a2a2a;
  border-radius: 4px;
}

#sidebar-menu ul li a i {
  color: #bebebe !important;
  font-size: 22px !important;
}

a img {
  margin-right: 8px;
}

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}

.bottom-nav-item {
  width: 250px;
  position: relative;
  padding: 8px 16px;
}

.bottom-nav-item a {
  padding: 8px 24px;
}

.bottom-nav-item a:hover {
  text-decoration: none;
  color: #fff;
  background: #2a2a2a;
}
