//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $fw-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

.settings-tabs {
  .nav-item {
    position: relative;
    &:not(.active) + .nav-item:not(.active):before {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: 100%;
      background: #D7D7D9;
    }
    &:first-child .nav-link {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid #D7D7D9;
    }
    &:last-child .nav-link {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #D7D7D9;
    }
  }
  .nav-link {
    height: 40px;
    padding: 5px 24px;
    border-radius: 0;
    color: #181718;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: 20px;
    &:not(.active) {
      border-top: 1px solid #D7D7D9;
      border-bottom: 1px solid #D7D7D9;
      &:hover {
        color: #1e1a92;
      }
    }
    &.active {
      cursor: default;
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
