.mat-stepper-horizontal {
  background: none;
  .mat-horizontal-content-container {
    padding: 0;
  }
  .mat-step-header {
    &.cdk-keyboard-focused, &.cdk-program-focused, &:hover:not([aria-disabled]), &:hover[aria-disabled=false] {
      background-color: transparent;
    }
    .mat-step-icon {
      width: 32px;
      height: 32px;
      &:not(.mat-step-icon-selected):not(.mat-step-icon-state-done) {
        border: 1px solid #d7d7d9;
        background: none;
        color: #595859;
      }
      &.mat-step-icon-selected, &.mat-step-icon-state-done {
        background: $primary;
        color: #fff;
      }
      .mat-step-icon-content {
        font-family: Inter;
        font-size: 14px;
      }
    }
    .mat-step-label {
      font-family: Inter;
      font-size: 14px;
      line-height: 22px;
      color: #595859;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 8px;
      &.mat-step-label-active {
        color: $primary;
      }
    }
  }
  .mat-step-header:has(> .mat-step-icon.mat-step-icon-state-done) + .mat-stepper-horizontal-line,
  .mat-step-header:has(> .mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected) + .mat-stepper-horizontal-line {
    border-top-color: $primary;
  }
  .mat-horizontal-content-container {
    margin-top: 16px;
  }
}
.mat-stepper-label-position-bottom {
  .mat-horizontal-stepper-header {
    padding: 0 16px;
    &:not(:first-child)::before, &:not(:last-child)::after {
      border-top-color: transparent;
    }
  }
  .mat-stepper-horizontal-line {
    top: 16px;
  }
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
  justify-content: space-around;
}
