/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Customer specific from angular.json:stylePreprocessorOptions
@import "customer-specific";

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/apexcharts";
@import "custom/plugins/chartist";
@import "custom/plugins/echarts";
@import "custom/plugins/google-map";
@import "custom/plugins/leaflet-maps";
@import "custom/plugins/owl-carousel";
@import "custom/plugins/spinner";

// Pages
@import "custom/pages/coming-soon";

// Modal windows
ngb-modal-backdrop {
  // Need for '@ng-bootstrap/ng-bootstrap@14' (NgbModal) - modal-backdrop has hire index, than window. Could be fixed next version.
  z-index: 1050 !important;
}

.cdk-overlay-container {
  z-index: 1050;
}

mark {
  color: #f46a6a;
  background: transparent !important;
  font-weight: 600;
  padding: 0 !important;
}

.dropdown-menu mark {
  color: var(--bs-primary) !important;
}

.btn.btn-action-icon:disabled, .btn.btn-action-icon.disabled, .btn.btn-action-icon-delete:disabled, .btn.btn-action-icon-delete.disabled {
  border-color: transparent;
}
