.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  background-color: #eff2f7;
  font-size: 0.8125rem;
  color: #495057;
  font-weight: 600;
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  font-size: 0.8125rem;
}

.ngx-datatable.material {
  box-shadow: none;
}
.ngx-datatable.material .datatable-header {
  border: none;
}

.ngx-datatable.scroll-x .datatable-body {
  overflow-x: visible !important;
}

.datatable-body-row {
  border-top: 1px solid #f6f6f6 !important;
}

@media only screen and (max-width: 1200px) {
  .ngx-datatable.scroll-horz .datatable-body {
    overflow-x: auto !important;
  }
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.5rem 1.2rem;
}

.table-hide-info .page-count {
  display: none;
}

.ngx-datatable.material.table-hide-info .datatable-body-row {
  border: none !important;
}
